import React, {useEffect, useState} from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
    const [qrCodeString, setQrCodeString] = useState<string | null>(null);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const qrCodeStringParam = searchParams.get('qrCode');
        setQrCodeString(qrCodeStringParam);
    }, []);

    return (
        <div className={"bg-[#F9FAFB] w-screen h-screen flex flex-row items-center justify-center"}>
            <div className={"w-[80%] h-full flex flex-col items-center"}>
                <div className={"flex flex-col w-full h-[25%] items-center pt-[15%]"}>
                    <img alt="logo" className="w-32" src={"./logo.svg"}/>
                </div>
                <div className={"w-full flex flex-col items-center justify-end gap-8"}>
                    <div className={"bg-white px-6 py-7 rounded-[2rem]"} style={{filter: "drop-shadow(0 1px 5px rgba(0, 0, 0, 0.03))"}}>
                        <img alt="qrCode" src={`https://api.qrserver.com/v1/create-qr-code/?data=${qrCodeString}&size=240x240`}/>
                    </div>
                    <p className={"w-[65%] text-center text-[#999999] font-Gilroy-Semi-Bold"} style={{fontSize: "18px", lineHeight: "18px"}}>Press and hold the QR code and then press on "add eSIM" to install</p>
                </div>

            </div>
        </div>
    )
}

export default App;
